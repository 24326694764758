<template>
  <div class="social-icon">
    <a
      v-for="(icon, i) in socialIconList"
      :key="i"
      :data-hover="icon.dataHover"
      :href="icon.url"
      target="_blank"
      >{{ icon.name }} <span class="circle"></span
    ></a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        socialIconList: [
          {
            name: "FB",
            dataHover: "FB",
            url: "https://www.facebook.com/groups/1015437091992388",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
