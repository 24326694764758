import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router/router';
import axios from 'axios';
import VueScrollactive from 'vue-scrollactive';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "hooper/dist/hooper.css";
import './assets/scss/style.scss'

import * as VeeValidate from 'vee-validate';
import lt from 'vee-validate/dist/locale/lt';

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

// Install the Plugin.
Vue.use(VeeValidate);
// Localize takes the locale object as the second argument (optional) and merges it.
VeeValidate.localize('lt', lt);
VeeValidate.localize('lt'); // changes the locale

const i18n = new VueI18n({
    locale: 'lt', // set locale
  })

Vue.config.productionTip = false
Vue.use(VueScrollactive);
Vue.prototype.$http = axios

i18n.locale = 'lt'

new Vue({
    i18n,
    vuetify,
    router,
    render: h => h(App),
}).$mount('#app')